import { helpers as v_helpers,
         required as v_required, 
         requiredIf as v_requiredIf, 
         email as v_email,
       } 
from '@vuelidate/validators'

export const helpers = v_helpers

export const required = helpers.withMessage("Kérjük, töltse ki ezt a mezőt!", v_required)

export var requiredIf = v_requiredIf

export const email = helpers.withMessage("Kérjük, helyesen adja meg az email-címet!", v_email)

export const errorMessages = {
"required" : "Kérjük, töltse ki ezt a mezőt!",
"invalidEmail" : "Kérjük, helyesen adja meg az email-címet!",
"existingEmail" : "Az email-cím már használatban van!",
"formError" : "Hiba az űrlapon!",
"invalidPassword" : "A jelszó nem megfelelő!",
"passwordMinLength" : "Legalább 8 karakter hosszú legyen",
"passwordsNotMatch" : "A jelszavak nem egyeznek meg!",
"wrongDate" : "Hibás dátum!",
"earlierFirstVisit" : "Nem lehet 30 napnál korábbi!",
"validIRSZ" : "Az ápolóhoz nem tartozik ilyen irányítószám!"
};
